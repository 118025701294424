import React , {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import './anmelden.css';
import useInputHooks from '../hooks/useInputHooks';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { anmelden, getUser  } from '../redux/authSlice';
import useShowPass from '../hooks/useShowPass';


const Anmelden = () => {

  const [error, setError] = useState('');
  const navigate = useNavigate();



  const dispatch = useDispatch();


  const user = getUser();

   useEffect(() => {
    navigate('/projekte');
   } , [user])

  const {
    value: email ,
    valueIsValid: emailIsValid ,
    hasError: emailHasError,
    getValueChangeHandler: emailChangeHandler,
    getValueOnBlur: emailOnBlurHandler,
    resetValue: emailReset,
    errMsg: emailErrorMsg,
  } = useInputHooks(wert => wert.trim() !== '');

  const {
    value: password,
    valueIsValid: passwordIsValid,
    hasError: passwordHasError,
    getValueChangeHandler: passwordChangeHandler,
    getValueOnBlur: passwordOnBlur,
    resetValue: resetPassword,
    errMsg: passwordError,
   } = useInputHooks(wert => wert.trim() !== '' );

   let formIsValid = false ;

   if(emailIsValid && passwordIsValid) {
    formIsValid = true;
   }

   const {
    isShowPass, 
    showPasswordHandler
   } = useShowPass();


     const anmeldenHandler = async (e) => {
      e.preventDefault();
      setError('');
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/wikomusers/anmelden`, {
          email , password
        })
  
        const loggedUser = await response.data;
        if(!loggedUser){
         setError("Error ist aufgetreten");
        }
        console.log(loggedUser);
        dispatch(anmelden(loggedUser))
        localStorage.setItem('user' , JSON.stringify(loggedUser));  
        
        const sessionZeit = new Date();
        sessionZeit.setMinutes(sessionZeit.getMinutes() + 1);
        localStorage.setItem('sessionZeit' ,  sessionZeit.toISOString()); 



      } catch (err) {
        setError(err.response.data.message);
      }
  
    }


  return (
    <div className='form__container_parent'>
        <div className="form__container">
        <h1>Wikom</h1>
       {error &&  <span className="error_message"> {error} </span>}
       { (emailHasError || passwordHasError) &&  <span className="error_message"> {emailErrorMsg("Bitte füllen Sie alle Felder aus")} </span>}
        <form action=""  onSubmit={anmeldenHandler}>
          <div className="email_input">
          <input type="email"  placeholder='email' name='email' value={email} onChange={emailChangeHandler} onBlur={emailOnBlurHandler}/>
          </div>
          <div className="passwort_input">
          <input type={isShowPass ? 'text' : 'password'} placeholder='password' name='password' value={password} onChange={passwordChangeHandler} onBlur={passwordOnBlur}/>
          <h6 className='showandHideBtn' onClick={showPasswordHandler}>{ !isShowPass ?   'EINBLENDEN'  : 'AUSBLENDEN' }</h6>
          </div>
              <p><Link to={'/passwort-zuruecksetzen'}>Passwort vergessen ?</Link></p>
        <div className="action_auth">
            <button type='submit'>Anmelden</button>
            <p>Haben Sie noch kein Konto !<Link to={`/regestrieren`}>Registrieren</Link></p>
        </div>
        </form>
        </div>
    </div>
  )
}

export default Anmelden