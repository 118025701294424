import React, { useEffect, useState } from 'react';
import {MdOutlineDeleteOutline} from 'react-icons/md'
import {FaPlusCircle} from 'react-icons/fa';
import axios from 'axios';
import {  useSelector } from 'react-redux';
import TicketModalVersuch from './TicketModalVersuch';
import './schritt.css';
import './ticket.css';
import {DragDropContext , Draggable , Droppable} from 'react-beautiful-dnd';


let timeout;

const KomponentAnzeigen = (props) => {

    const {  projektId   , showModalHandler , hideModalHandler , isShowModal  , suchFeld} = props ;
    
    
    const token = useSelector((state) => state.auth.token);
    const [data , setData] = useState([]);
    


    useEffect(() => {
      clearTimeout(timeout);
       timeout = setTimeout(() => {

        const  getProjekt = async () => {

          try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/intern/p/${projektId}` , {
            withCredentials: true ,
            headers: {Authorization: `Bearer ${token}`}
            })
  
  
            setData(response.data.schritten);
            
          } catch (error) {
            console.log(error);
          }
  
        }
        getProjekt();

      } , 500)     

    } , [data , projektId])
   


    const [schrittTitle , setSchrittTitle] = useState('Überschrift');





    
    const addSchritt = async () => {
        
        try {

          const  response = await  axios.post(`${process.env.REACT_APP_BASE_URL}/users/intern/p/schritt/${projektId}/add` , {title: schrittTitle} ,
            {
            withCredentials: true,
            headers: {Authorization: `Bearer ${token}`}
          })


          if(response.status == 200){
            setData(prevList => [...prevList, response.data]);
          }

        } catch (error) {
          console.log(error);
        }
    }





    const updateSchrittTitle = async (e, schrittID) => {
      const newTitle = e.target.value;
      const newData = [...data];
      const index = newData.findIndex((e) => e._id === schrittID);
      newData[index].title = newTitle;
    
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/users/intern/p/schritt/${projektId}/s/${schrittID}`,
          { title: newTitle },
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
    
        if (response.status === 200) {
          setData(newData); 
        }
      } catch (error) {
        console.log(error);
      }
    };

  


  const deleteSchrittHandler = async ( schrittID) => {

    try {

        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/users/intern/p/schritt/${projektId}/s/${schrittID}`,
        {
            withCredentials: true,
            headers: {Authorization: `Bearer ${token}`}
          }
        )

        const newData = [...data];  
        let tempSchrittArray = JSON.parse(JSON.stringify(newData));
        const newSchrittList = tempSchrittArray.filter((e) => e._id !== schrittID);
        setData(newSchrittList);
        
    } catch (error) {
        console.log(error);
    }

}    


    const  [ticketTitle , setTicketTitle] = useState('Ticket Title');
    const  [ticketBeschreibung , setTicketBeschreibung] = useState('Ticket Beschreibung');
    const [selectedTicket, setSelectedTicket] = useState(undefined)


    const ticketEinfuegenHandler = async (schrittID) => {

        try {
    
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/intern/p/schritt/ticket/${schrittID}`,
             {title : ticketTitle  , beschreibung : ticketBeschreibung} ,
            {
                withCredentials: true,
                headers: {Authorization: `Bearer ${token}`}
              }
               
            )

            const newData = [...data];
            const index = newData.findIndex((e) => e._id === schrittID);
            newData[index].ticket.push(response.data);
            setData(newData);


        } catch (error) {
            console.log(error);
        }
      }


   

      const updateTicketHandler = (meinTicket , title , beschreibung) => {
        const newData = [...data];
        const schrittIndex = newData.findIndex((e) => e._id === meinTicket.schrittId._id);
        const ticketIndex = newData[schrittIndex].ticket.findIndex((e) => e._id === meinTicket._id);

        newData[schrittIndex].ticket[ticketIndex].title = title;
        newData[schrittIndex].ticket[ticketIndex].beschreibung = beschreibung;
        setData(newData);
      }

      const deleteTicketHandler = (meinTicket) => {
        const newData = [...data];
        const schrittIndex = newData.findIndex((e) => e._id === meinTicket.schrittId._id);
        const ticketIndex = newData[schrittIndex].ticket.findIndex((e) => e._id === meinTicket._id);
        newData[schrittIndex].ticket.splice(ticketIndex , 1);
        setData(newData)
      }


      const ticketZuweisenHandler = (meinTicket , user) => {
        const newData = [...data];
        let tempSchrittArray = JSON.parse(JSON.stringify(newData));
        const schrittIndex = tempSchrittArray.findIndex((e) => e._id === meinTicket.schrittId._id);
        const ticketIndex =  tempSchrittArray[schrittIndex].ticket.findIndex((e) => e._id === meinTicket._id);
        tempSchrittArray[schrittIndex].ticket[ticketIndex].user = user ;
        setData(tempSchrittArray);
      }

      const ticketNichtMehrZuweisenHandler = (meinTicket) => {
        const newData = [...data];
        let tempSchrittArray = JSON.parse(JSON.stringify(newData));
        const schrittIndex = tempSchrittArray.findIndex((e) => e._id === meinTicket.schrittId._id);
        const ticketIndex =  tempSchrittArray[schrittIndex].ticket.findIndex((e) => e._id === meinTicket._id);
        tempSchrittArray[schrittIndex].ticket[ticketIndex].user = null ;
        setData(tempSchrittArray);
      }


    const meineTicket = (ticket) => {
       setSelectedTicket(ticket);
       showModalHandler();
    }







    const onDragEnd = async ({ source, destination }) => {
      if (!destination) return;
    
      const newData = [...data];
      let tempSchrittArray = JSON.parse(JSON.stringify(newData));
    
      const sourceSpalteIndex = tempSchrittArray.findIndex((e) => e._id === source.droppableId);
      const destinationSplateIndex = tempSchrittArray.findIndex((e) => e._id === destination.droppableId);
    
      const sourceSpalte = tempSchrittArray[sourceSpalteIndex];
      const destinationSpalte = tempSchrittArray[destinationSplateIndex];
    
      const sourceSchrittId = sourceSpalte._id;
      const destinationSchrittId = destinationSpalte._id;
    
      const sourceTicket = [...sourceSpalte.ticket];
      const destinationTicket = [...destinationSpalte.ticket];
    
      if (source.droppableId !== destination.droppableId) {
        const [removed] = sourceTicket.splice(source.index, 1)
        destinationTicket.splice(destination.index, 0, removed)
        tempSchrittArray[sourceSpalteIndex].ticket = sourceTicket
        tempSchrittArray[destinationSplateIndex].ticket = destinationTicket
      } else {
        const [removed] = destinationTicket.splice(source.index, 1)
        destinationTicket.splice(destination.index, 0, removed)
        tempSchrittArray[destinationSplateIndex].ticket = destinationTicket
      }
    

    setData(tempSchrittArray);
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/users/intern/p/schritt/ticket/s/pos-ticket/aktualisieren`,
          {
            sourceTicketList: sourceTicket,
            destinationTicketList: destinationTicket,
            sourceSchrittId: sourceSchrittId,
            destinationSchrittId: destinationSchrittId,
          },
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
    
        if (response.status === 200) {
          setData((prevData) => {
            const newData = [...prevData];
            newData.splice(sourceSpalteIndex, 1, { ...sourceSpalte, ticket: sourceTicket });
            newData.splice(destinationSplateIndex, 1, { ...destinationSpalte, ticket: destinationTicket });
            return newData;
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    
   
  return (
    <>
        <button  className='addSchritt'  onClick={addSchritt}>Schritt Einfügen</button>
          <DragDropContext onDragEnd={onDragEnd} >


    <div className="dashboard_schritt">
        {
            data.map(schritt => (
                <div className="schritt_parent">
                  <Droppable key={schritt._id} droppableId={schritt._id}>

           {
            (provided) => (
       
                <>
                <header>
                <h2 onClick={() => deleteSchrittHandler(schritt._id)} ><MdOutlineDeleteOutline/></h2>
                <p>
                 <input  type='text' name='title'  value={schritt.title} onChange={(e) => updateSchrittTitle(e , schritt._id)} />
                </p>
                 <button onClick={() => ticketEinfuegenHandler(schritt._id)} ><FaPlusCircle/></button>
                </header>


    
         
          
                 <div className="ticket__parent" ref={provided.innerRef} {...provided.droppableProps}>
         
                     {
                         schritt.ticket.filter(ticket => ticket.title.toLowerCase().includes(suchFeld.toLowerCase())).map((ticket , index) => (
                            
                            <>
                            <Draggable key={ticket._id} draggableId={ticket._id} index={index}>
                       {
                          (provided , snapshot) => 
                          (
                             <div className='einzelne_ticket' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                <p  className='ticket_child'onClick={() => meineTicket(ticket)} >{ticket.title}<br/>
                                </p>
                            </div>
                          )
                        }
                            </Draggable>
                            </>
                         ))
                     }
                      {provided.placeholder}
         
                 </div>
          
        
                 <footer className='ticket_footer'>
                     <h6>Wikom</h6>
                 </footer>

                 </>
                )}
                 </Droppable>

             </div>
         
            ))
        }
    </div>
    </DragDropContext>

    <TicketModalVersuch  
        ticket={selectedTicket}
        projektId={projektId}
        deleteTicketHandler={deleteTicketHandler}
        updateTicketHandler={updateTicketHandler}
        ticketZuweisenHandler = {ticketZuweisenHandler}
        ticketNichtMehrZuweisenHandler = {ticketNichtMehrZuweisenHandler}
        showModalHandler={showModalHandler}
        hideModalHandler={hideModalHandler}
        isShowModal={isShowModal}
        onClose={() => setSelectedTicket(undefined)}
    
    />
    </>
  )
}

export default KomponentAnzeigen