import React from 'react';
import './loading.css';

const Loading = () => {
  return (
    <div className='loading_parent'>
        <div className='loading'></div>
    </div>
  )
}

export default Loading