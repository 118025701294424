import {configureStore} from '@reduxjs/toolkit';
import authSlice from './authSlice';
import projektSlice from './projektSlice';
import ticketSlice from './ticketSlice';
import schrittSlice from './schrittSlice';



const store = configureStore({
    reducer:{ 
        auth: authSlice.reducer,
        projekt: projektSlice.reducer,
        schritt: schrittSlice.reducer,
        ticket: ticketSlice.reducer,
    },
    middleware: (getDefaultMiddlware) => getDefaultMiddlware({
        serializableCheck: false
    }),
})

export default store;