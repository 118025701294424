import  { createBrowserRouter, RouterProvider} from 'react-router-dom';
import { useEffect } from 'react';
import AuthLayout from './layout/AuthLayout';
import AngemeldeteLayout from './layout/AngemeldeteLayout';
import Anmelden from './pages/Anmelden';
import Registrieren from './pages/Registrieren';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import { useDispatch, useSelector } from 'react-redux';
import Error from './pages/Error';
import PasswortZuruecksetzen from './pages/PasswortZuruecksetzen';
import Verifikation from './pages/Verifikation';
import NeuPasswortAnlegen from './pages/PasswortAnlegen';
import { abgelaufeneZeitAktualisieren, checkAuthLoader, loaderUser } from './util/auth';
import { abmelden } from './redux/authSlice';

function App() {

    const dispatch = useDispatch();

    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);



    const pruefenInactivitaet = () => {
      const abgelaufeneZeit = localStorage.getItem('sessionZeit');

      if(abgelaufeneZeit < Date.now()){
        console.log("logout");
        dispatch(abmelden());
      }
 
    }



    useEffect(() => {


      const interval = setInterval(() =>{
        pruefenInactivitaet();
      } , 5000)
            return () => clearInterval(interval);
    } , [])

    useEffect(() => { 

      
      abgelaufeneZeitAktualisieren();



      window.addEventListener("click" , abgelaufeneZeitAktualisieren)
      window.addEventListener("keypress" , abgelaufeneZeitAktualisieren)
      window.addEventListener("scroll" , abgelaufeneZeitAktualisieren)
      window.addEventListener("keydown" , abgelaufeneZeitAktualisieren)
      window.addEventListener("mousemove" , abgelaufeneZeitAktualisieren)

      return () => {
        window.removeEventListener("click" , abgelaufeneZeitAktualisieren)
        window.removeEventListener("keypress" , abgelaufeneZeitAktualisieren)
        window.removeEventListener("scroll" , abgelaufeneZeitAktualisieren)
        window.removeEventListener("keydown" , abgelaufeneZeitAktualisieren)
        window.removeEventListener("mousemove" , abgelaufeneZeitAktualisieren)
      }

    }, [])


    const router = createBrowserRouter([
      {
        path: '/',
        element: <AuthLayout />,
        errorElement: <Error />,
        id: 'root',
        children: [
          {
            index: true, 
            element: <Anmelden />,
          },
          {
            path: 'regestrieren',
            element: <Registrieren />,
          },
          {
            path: 'passwort-zuruecksetzen',
            element: <PasswortZuruecksetzen />,
          },
          {
            path: 'passwort-zuruecksetzen/verifikation/:email/:tokenId',
            element: <Verifikation />,
          },
          {
            path: 'passwort-zuruecksetzen/verifikation/neu-passwort',
            element: <NeuPasswortAnlegen />
          },
          {
            path: '/',
            element: <AngemeldeteLayout />,
            loader: loaderUser,
            children: [
              {
                index: true,
                element: <Home />
              },
              {
                path: 'projekte',
                element: <Dashboard />,
                loader: checkAuthLoader
              },
              {
                path: 'projekte/:projektId',
                element: <Dashboard />,
                loader: checkAuthLoader,
              },

            ]
          }

        ]

        
      }
    ])
  
  return (
    <RouterProvider router={router} />
  )
}

export default App;
