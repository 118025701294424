import React, { useEffect, useState } from 'react';
import {CgProfile} from 'react-icons/cg';
import {FaPlus} from 'react-icons/fa';
import {CiLogout} from 'react-icons/ci'
import './sidebar.css';
import EinzelneProjekt from './EinzelneProjekt';
import {useDispatch, useSelector} from 'react-redux';
import { abmelden } from '../redux/authSlice';
import {  useNavigate } from 'react-router-dom';
import axios from 'axios'
import { setProjekte } from '../redux/projektSlice';
import {DragDropContext , Draggable , Droppable} from 'react-beautiful-dnd';


const Sidebar = (props) => {

      const {projektId , angemeldeteUser} = props; 
     const user = useSelector((state) => state.auth.user);
     const token = useSelector((state) => state.auth.token);

     const allProjekt = useSelector((state) => state.projekt.value);



    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [title , setTitle] = useState('Überschrift');



    


    useEffect(() => {
        const getAllProjekt = async () => {
          try{
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/intern/p`
                ,{withCredentials: true}
                ) 
                
            dispatch(setProjekte(res.data))
          }catch(err){
            alert(err);
          }
         
        }
        getAllProjekt();
      }, [dispatch])


    const addProjektHandler = async () => {
        try {
          const response = await  axios.post(`${process.env.REACT_APP_BASE_URL}/users/intern/p` 
          ,{title} 
          ,{withCredentials: true
          , headers: {Authorization:  `Bearer ${token}`}}
          )

          const newList = [response.data , ...allProjekt];

          dispatch(setProjekte(newList));
          navigate(`/projekte/${response.data._id}`)
          
        } catch (err) {
            console.log(err);
        }
      }
    const abmeldenHandler = () => {
        dispatch(abmelden());
        navigate('/');
    }

   

    const [activeIndex, setActiveIndex] = useState(0);

    const onDragEnd = async ({ source, destination }) => {

      if(!destination) return ;
      const newList = [...allProjekt];
    
      const [removed] = newList.splice(source.index, 1);
      newList.splice(destination.index, 0, removed);
    
      const activeItem = newList.findIndex(e => e._id === projektId);    
      setActiveIndex(activeItem);
      dispatch(setProjekte(newList));
    
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/users/intern/p`,
          { projekten: newList },
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
    
      } catch (error) {
        console.log(error);
      }
    };
    

  return (
        <section className="sidebar__parent">
            
            <div className="infos__user">
            <span><CgProfile /></span>
            <h4>{angemeldeteUser.name.split(' ')[0]}</h4>
            <span className='logout' onClick={abmeldenHandler}><CiLogout /></span>
            </div>

            <div className="projekt__container">
            
                <div className="projekt__action">
                <h4>Projekte</h4>
                <button onClick={addProjektHandler}><FaPlus /></button>
                </div>
               
   

        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable key={'projekt-droppable'} droppableId='projekt-droppable'>
   
   {
     (provider) => (
               <div className="projekt__infos" ref={provider.innerRef} {...provider.droppableProps}>
                {
                   allProjekt.map((projekt , index) => (
                        <EinzelneProjekt index={index}  key={projekt._id} id={projekt._id} title={projekt.title}/>
                    ))
                }
                {provider.placeholder}

               </div>
     )
    }
             </Droppable>
        </DragDropContext>

    

            </div>
        </section>
    )
}

export default Sidebar