import { createSlice } from "@reduxjs/toolkit";


const initialSchrittSlice = {value : []};


const schrittSlice = createSlice({
    name: 'user',
    initialState: initialSchrittSlice,
    reducers: {
        setSchritte : (state , action) => {
            state.value = action.payload
        }
    }

})


export const { setSchritte } = schrittSlice.actions

export default schrittSlice ;