import React , {useState} from 'react';
import './passwortzuruecksetzen.css';
import useInputHooks from '../hooks/useInputHooks';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

const Verifikation = () => {

    const {email ,tokenId} = useParams();

    const navigate = useNavigate();

    const [tokenInput , setTokenInput] = useState('');
    
    const [error, setError] = useState('');
    const [success, setSucces] = useState('');

    const linkVerifikation = async (e) => {
        e.preventDefault();
        setError('');
        setSucces('');

      

            try {

                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/wikomusers/passwort-zuruecksetzen/verifikation/${email}/${tokenId}`, {
                    tokenInput: tokenId,
                    email: email,
                })
    
                if(response.status === 200){
                    console.log("Email Erfolgreich geschickt");
                }
                const user = await response.data ;
                localStorage.setItem('userId' , user.id)
                setSucces('Bitte legen Sie Ihr neues Passwort in Kürze fest.')
                setTimeout(() => {
                    navigate('/passwort-zuruecksetzen/verifikation/neu-passwort');

                }, 3000)
                
            } catch (error) {
               setError("Fehler ist aufgetreten , bitte fangen Sie von vorne an")
            }

      
    }


    const tokenInputHandler = (event) => {
        setTokenInput(tokenId);
    }



  return (
    <div className='parent__password'>
        <form action="" onSubmit={linkVerifikation}>
        <header>
            <h1>Wikom</h1>
            <p>Passwort zurücksetzen</p>
        </header>
         {error && <p className='error_message'>{error}</p>}
         {success && <p className='success_message'>{success}</p>}
        <div className='inputFelder'>
            <p>
            Bitte überprüfen Sie regelmäßig Ihre E-Mail-Adresse,<br/>
 da Sie in Kürze eine Nachricht von uns erhalten werden. Sollten Sie den Code nicht automatisch erhalten, prüfen Sie bitte auch Ihren Spam-Ordner.
            </p>
            <h5>Bitte geben Sie Ihre Code </h5>
            <input  type='text' placeholder='code Eingeben'  name='tokenInput' value={tokenId} onChange={(e) => setTokenInput(e.target.value)}  />
            <button type='submit'>Weiter</button>
        </div>
        </form>
    </div>
  )
}

export default Verifikation