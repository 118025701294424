import React, { useState } from 'react';

const useShowModal = () => {
    const [isShowModal , setIsShowModal] = useState(false);

    const hideModalHandler = () =>{
        setIsShowModal(false);
    }

    const showModalHandler = () =>  {
        setIsShowModal(true);
    }

  return (
    {
        isShowModal,
        hideModalHandler,
        showModalHandler,
    }
  );
}

export default useShowModal
