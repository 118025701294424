import { createSlice } from "@reduxjs/toolkit";


const initialTicketSlice = {ticketZustand : undefined  , allTicket : [] , oneTicket : undefined};


const ticketSlice = createSlice({
    name: 'ticket',
    initialState: initialTicketSlice,
    reducers: {
        setTicket : (state , action) => {
            state.ticketZustand = action.payload
        },
        setAllTicket : (state , action) => {
            state.allTicket = action.payload
        },
        setOneTicket : (state , action) => {
            state.oneTicket = action.payload
        }
    }

})


export const { setTicket  , setAllTicket , setOneTicket}  = ticketSlice.actions

export default ticketSlice ;