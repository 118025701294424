import { createSlice } from '@reduxjs/toolkit';


const initialState = { value: []}

export const projektSlice  = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setProjekte: (state , action) => {
            state.value = action.payload
        }
    }
})

export const { setProjekte } = projektSlice.actions

export default projektSlice;