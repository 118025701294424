import React, { useEffect, useState } from 'react';
import './passwortzuruecksetzen.css';
import useInputHooks from '../hooks/useInputHooks';
import axios from 'axios';
import { storeEmail } from '../redux/user/userSlice';
import {Link} from 'react-router-dom';

const PasswortZuruecksetzen = () => {

    const [error, setError] = useState('');
    const [succes, setSucces] = useState('');
    const{
        value : email,
        valueIsValid: emailIsValid,
        hasError: emailHasError,
        getValueChangeHandler: emailChangeHandler,
        getValueOnBlur: emailOnBlur,
        resetValue: resetEmail,
        errMsg: emailError,
       } = useInputHooks(wert => wert.trim() !== '' && wert.includes('@'));


    const passwortZuruecksetzenHandler = async (e) => {
        e.preventDefault();
        setError('');
        setSucces('');

        if(emailIsValid && !emailHasError){

            try {

                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/wikomusers/passwort-zuruecksetzen`, {
                    email
                })
    
                setSucces(response.data);
                
            } catch (error) {
             setError(error.response.data.message);
            }

        }
       
    }



    


  return (
    <div className='parent__password'>
        <form action="" onSubmit={passwortZuruecksetzenHandler}>
        <header>
            <h1><Link to={'../'}>Wikom</Link> </h1>
            <p>Passwort zurücksetzen</p>
        </header>
         {emailHasError && <p className='error_message'>{emailError("Bitte geben Sie Ihre Email ")}</p>}
         {error && <p className='error_message'>{error}</p>}
         {succes && <p className='success_message'>{succes}</p>}
        <div className='inputFelder'>
            <h3>Bitte geben Sie Ihre Email Adresse</h3>
            <input  type='email' placeholder='email' name='email' value={email} onChange={emailChangeHandler}  onBlur={emailOnBlur}  />
            <button type='submit'>Weiter</button>
        </div>
        </form>
    </div>
  )
}

export default PasswortZuruecksetzen