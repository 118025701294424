import React , {useState} from 'react';


const useInputHooks = (valueValidate) => {

    const [value , setValue] = useState('');
    const [isTouched , setIsTouched] = useState(false);

    const valueIsValid = valueValidate(value);

    const hasError = !valueIsValid  && isTouched ;


    const getValueChangeHandler = (e) => {
        setValue(e.target.value);
    }

    const getValueOnBlur = (e) => {
        setIsTouched(true);
    }

    const resetValue = () => {
        setValue('');
        setIsTouched(false);
    }

    const errMsg = (msg) => {
        return <p>{msg}</p>;
    }

  return (
    {
        value,
        valueIsValid,
        hasError,
        getValueChangeHandler,
        getValueOnBlur,
        resetValue,
        errMsg,
    }
  )
}

export default useInputHooks
