import React, { useState } from 'react';

const useShowPass = () => {
    const [isShowPass , setIsShowPass] = useState(false);

   
    const showPasswordHandler = () => {
        setIsShowPass(!isShowPass);
    }

  return (
    {
        isShowPass,
        showPasswordHandler,
    }
  );
}

export default useShowPass
