import React, { useEffect, useState } from 'react'
import Sidebar from '../component/Sidebar'
import './dashboard.css';
import useShowModal from '../hooks/useShowModal';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setProjekte } from '../redux/projektSlice';
import { abmelden , getToken } from '../redux/authSlice';
import DeleteProjektModal from '../component/DeleteProjektModal';
import Wilkommen from './Wilkommen';
import KomponentAnzeigen from '../component/KomponentAnzeigen';
import Loading from '../component/Loading';

const Dashboard = () => {

    const [angemeldeteUser , setAngemeldeteUser] = useState({});
    const [title , setTitle] = useState('');
    const [beschreibung , setBeschreibung] = useState('');
    const [isLoading, setIsLoading] = useState(false);    
    const [storedProjekt , setStoredProjekt] = useState([]);
    const [projektTitle , setProjektTitle] = useState('');

    const navigate = useNavigate();
    const allProjekt = useSelector((state) => state.projekt.value);

    const dispatch = useDispatch();

    const  {projektId} = useParams();

    const user = useSelector((state) => state.auth.user);
     const token = useSelector((state) => state.auth.token);


     useEffect(() => {
    
      if(user === null){
        navigate('/');
      }else{
        setIsLoading(true);
        setAngemeldeteUser(user);
        dispatch(getToken(user.token));

        const timeout = setTimeout(() => {
          setIsLoading(false);
        } , 3000)

        return () => clearTimeout(timeout);

      }

    
    }, [user]);


    
    


     const [schrittList , setSchrittList] = useState([]);








    const addProjektHandler = async () => {
      try {
        const response = await  axios.post(`${process.env.REACT_APP_BASE_URL}/users/intern/p` 
        ,{title: projektTitle} 
        ,{withCredentials: true
        , headers: {Authorization:`Bearer ${token}`}}
        )
  
        
        const newList = [response.data , ...allProjekt];

        dispatch(setProjekte(newList));
        redirect(`/projekte`);
        window.location.reload(); 
      } catch (err) {
          console.log(err);
      }
    }







    


    

    useEffect(() => {
      const  getProjekt = async () => {

        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/intern/p/${projektId}` , {
          withCredentials: true ,
          headers: {Authorization: `Bearer ${token}`}
          })

          setTitle(response.data.title);
          setBeschreibung(response.data.beschreibung);          
        } catch (error) {
          console.log(error);
        }

      }


      getProjekt();
    } , [ projektId , allProjekt.length   ])




    useEffect(() => {
      const  getAllProjekt = async () => {

        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/intern/p` , {
          withCredentials: true ,
          headers: {Authorization: `Bearer ${token}`}
          })

         setStoredProjekt(response.data);
         navigate(`/projekte/${response.data[0]._id}`)
        } catch (error) {
          console.log(error);
        }

      }


      getAllProjekt();
    } , [])



   


    const {
      hideModalHandler : hideDeleteDialog,
      showModalHandler:  showDeleteDialog,
      isShowModal : isShowDeleteDialog,
    } = useShowModal();


    const deleteProjektHandler = async() => {

      try {
          const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/users/intern/p/${projektId}` ,
          {
            withCredentials: true,
            headers: {Authorization: `Bearer ${token}`}
          }
          )

         
          const newProjektList = allProjekt.filter(e => e._id !== projektId)
          
          if(newProjektList.length === 0){
            window.location.reload();
          }
        
          if(newProjektList.length > 0 || newProjektList.length > 0){
            navigate(`/projekte/${newProjektList[0]._id}`)
          }

          
          dispatch(setProjekte(newProjektList));

         
        
      } catch (error) {
        console.log(error);
      }
    }


    const updateTitle = async (e) => {
      const newTitle = e.target.value;
      setTitle(newTitle);

      let temp = [...allProjekt];
      const index = temp.findIndex(e => e._id === projektId);
      temp[index] = {...temp[index] , title : newTitle};
      dispatch(setProjekte(temp));
      try {
        
          const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/users/intern/p/${projektId}` , {
            title : newTitle 
          } , {
            withCredentials : true, 
            headers: {Authorization: `Bearer ${token}`}
          } 
          )

          

      } catch (error) {
        console.log(error);
      }

    };


    const updateBeschreibung = async (e) => {


        const newBeschreibung = e.target.value;
        setBeschreibung(newBeschreibung);

        const temp =[...allProjekt];
        const index = temp.findIndex(e => e._id === projektId);

        temp[index] = {...temp[index] , beschreibung: newBeschreibung }

        dispatch(setProjekte(temp))

        try {
          const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/users/intern/p/${projektId}`, {
            beschreibung: newBeschreibung
          },
          {
            withCredentials: true,
            headers: {Authorization: `Bearer ${token}`}
          }
          )
          
        } catch (error) {
          console.log(error);
        }

    };




    const [suchFeld, setSuchFeld] = useState('');



    const suchFeldHandler = (e) => {
      const currentSuchFeld = e.target.value;
      setSuchFeld(currentSuchFeld);
    }

    const {
      hideModalHandler,
      showModalHandler,
      isShowModal
    } = useShowModal();


    const abmeldenHandler = () => {
      dispatch(abmelden());
      navigate('/');
  }

    const aktuallTicket = useSelector((state) => state.ticket.ticketZustand || undefined);

  


  return storedProjekt.length > 0 ?  isLoading ? <Loading /> : (
     
      <>
        <div className="dashboard_parent">
            <h1>Wikom</h1>
            <Sidebar  projektId={projektId} angemeldeteUser={angemeldeteUser} />
            <input className='suchFeld'  type='text' placeholder='Name des Ticket'  value={suchFeld} onChange={suchFeldHandler}/>

            <div className='dashboard__infos_parent'>


                <div className='title_Description'>
                    <p>
                        <input id='title' type='text' name='title' value={title  ? title : ''} onChange={updateTitle}/><br/>
                        <textarea  id='description' type='text' name='beschreibung' value={beschreibung  ? beschreibung : ''} onChange={updateBeschreibung}></textarea> <br/>
                       
                    </p>
                    <div className="action">
                    <button onClick={showDeleteDialog} >Projekt Löschen</button>
                    </div>
                </div>

                <KomponentAnzeigen  schrittList={schrittList}  projektId={projektId} aktuallTicket={aktuallTicket} showModalHandler={showModalHandler} hideModalHandler={hideModalHandler} isShowModal={isShowModal} suchFeld={suchFeld}/>
            
            </div>
          
           
        
        </div>

        <DeleteProjektModal 
          showDeleteDialog= {showDeleteDialog}
          isShowDeleteDialog = {isShowDeleteDialog}
          hideDeleteDialog= {hideDeleteDialog}
          deleteProjektHandler= {deleteProjektHandler}
          projektTitle = {title}
        
        />
      
    </>

    )  : <Wilkommen projektTitle={projektTitle} setProjektTitle={setProjektTitle} addProjektHandler={addProjektHandler} abmelden={abmeldenHandler} />
}

export default Dashboard