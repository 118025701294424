import React, { useState } from 'react';
import './passwortzuruecksetzen.css';
import useInputHooks from '../hooks/useInputHooks';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useShowPass from '../hooks/useShowPass';


const NeuPasswortAnlegen = () => {


    const userId = localStorage.getItem('userId');

    const [error, setError] = useState('');
    const [success, setSucces] = useState('');
    const navigate = useNavigate();
    const {
        value: password,
        valueIsValid: passwordIsValid,
        hasError: passwordHasError,
        getValueChangeHandler: passwordChangeHandler,
        getValueOnBlur: passwordOnBlur,
        resetValue: resetPassword,
        errMsg: passwordError,
       } = useInputHooks(wert => wert.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{3,}$/) && wert.length > 7 );
  
       const {
        value: confirmPassword,
        valueIsValid: confirmPasswordIsValid,
        hasError: confirmPasswordHasError,
        getValueChangeHandler: confirmPasswordChangeHandler,
        getValueOnBlur: confirmPasswordOnBlur,
        resetValue: resetConfirmconfirmPassword,
        errMsg: confirmPasswordError,
       } = useInputHooks(wert => wert.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{3,}$/) && wert.length > 7 );
  

       const neuPasswortAnlegenHandler = async (e) => {
        e.preventDefault();
        setError('');
        setSucces('');

      

            try {

                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/wikomusers/neu-passwort`, {
                    newPassowrt: password,
                    confirmNewPasswort: confirmPassword,
                    userId: userId,
                })
    
                

                setSucces(`${response.data} Bitte warten Sie kurz`);
                localStorage.removeItem('userId');
                setTimeout(() => {
                    navigate('/');
                }, 3000);

                
            } catch (error) {
                setError(error.response.data.message)
            }

      
    }

    const {
        isShowPass ,
        showPasswordHandler
    } = useShowPass();

    const {
        isShowPass: showConfirmPass,
        showPasswordHandler: showConfirmPassHandler,

    } = useShowPass();


    return (
        <div className='parent__password'>
            <form action="" onSubmit={neuPasswortAnlegenHandler}>
            <header>
                <h1>Wikom</h1>
                <p>Neue Passwort anlegen</p>
            </header>
             {passwordHasError  && <p className='error_message'>{passwordError("Das Passwort sollte mindestens 8 Zeichen lang sein und sowohl Klein- als auch Großbuchstaben, Sonderzeichen und Ziffern enthalten.")}</p> }
             {password !== confirmPassword && <p className='error_message'>{confirmPasswordError("Passwörter sind nicht ähnlich")}</p> }
             {error && <p className='error_message'>{error}</p> }
             {success && <p className='success_message'>{success}</p> }

            <div className='passwort_input_a'>
            <div >
            <input  type={!isShowPass ? 'password' : 'text'} name='neuPass' value={password} onChange={passwordChangeHandler} onBlur={passwordOnBlur} placeholder='Neu Passwort eingeben'   />
            <h6 onClick={showPasswordHandler}>{ !isShowPass ?   'EINBLENDEN'  : 'AUSBLENDEN' }</h6>
            </div>
            <div >
            <input  type={ !showConfirmPass ? 'password' : 'text'} name='confirmNeuPass' value={confirmPassword} onChange={confirmPasswordChangeHandler} onBlur={confirmPasswordOnBlur} placeholder='Neu Passwort bestätigen'   />
            <h6 className='showandHideBtn' onClick={showConfirmPassHandler}>{ !showConfirmPass ?   'EINBLENDEN'  : 'AUSBLENDEN' }</h6>

            </div>
            <button>Passwort speichern</button>
            </div>
            
            </form>
        </div>
      )
}

export default NeuPasswortAnlegen;