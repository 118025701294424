import { createSlice } from "@reduxjs/toolkit";


export const getUser = () => JSON.parse(localStorage.getItem('user'));
export const getSessionZeit = () => JSON.parse(localStorage.getItem('sessionZeit'));

const initialAuthState = {
    user: getUser() ? getUser() : null,
    isLoggedIn: getUser() ? true : false,
    token: null,
    isLoading: false,
}


const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        getToken: (state , action) => {
            state.token = action.payload;
        },
        anmelden: (state , action) => {
            state.user = action.payload;
           state.isLoggedIn = true;
        },
        isLoadingStatus: (state , action) => {
            state.isLoading = action.payload;
        },
        abmelden: (state , action) => {
            localStorage.removeItem('user');
            localStorage.removeItem('sessionZeit');
            state.user = null;
            state.isLoggedIn = false;
            state.token = null;
            state.isLoading = false;
        },
    },
})


export const { getToken , anmelden, isLoadingStatus , abmelden} = authSlice.actions;

export const currentUser = (state) => state.user.user;

export default authSlice;