import React, { Fragment } from 'react';
import  ReactDOM  from 'react-dom';
import classes from './Modal.module.css';

const BackDrop = ()  =>{
    return <div className={classes.backDrop}></div>
}

const ModalOverlay =({children}) =>{
    return <div className={classes.modal}>
        <div>{children}</div>
    </div>
}
const portalElement = document.getElementById('overlay');

const Modal = ({children}) => {
  return (
    <Fragment>
        {ReactDOM.createPortal(<BackDrop/>, portalElement)}
        {ReactDOM.createPortal(<ModalOverlay>{children}</ModalOverlay>, portalElement)}
    </Fragment>
  )
}

export default Modal
