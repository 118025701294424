import React , {useState}  from 'react'
import Modal from '../ui/Modal';
import './deleteProjektModal.css';

const DeleteProjektModal = (props) => {

    const {isShowDeleteDialog , hideDeleteDialog , deleteProjektHandler , projektTitle} = props 


    const [projektName, setprojektName] = useState('');
    const [error, setError] = useState('');


    const deleteProjekt = () => {
        setError('');

        if(projektName ===  ''){
            setError("Bitte Geben Sie die Name des Projekts")
        }
        else if(projektName === projektTitle){
            deleteProjektHandler();
            hideDeleteDialog();
            setprojektName('');
        }else{
            setError("Projektname ist falsch");
        }
      
    }

  return isShowDeleteDialog && (
    <Modal>
        <div className='delete_dialog'>
                     {error && <p className="error_message">{error}</p>}
            Möchten Sie Wirklich diese Projekt "<strong>{projektTitle}</strong>" Löschen ??? <br/>
                    Bitte geben Sie die Name dieses Projektes <br/>
            <input type="text" name='projektName' value={projektName} onChange={(e) => setprojektName(e.target.value)} onPaste={(e) => e.preventDefault()} />
            <div className='action_btn'>
                <button onClick={hideDeleteDialog}>Nein</button>
                <button onClick={deleteProjekt}>Ja</button>
            </div>
        </div>
    </Modal>
  )
}

export default DeleteProjektModal