import React from 'react'
import { Link } from 'react-router-dom';
import {VscError} from 'react-icons/vsc';
import classes from './error.module.css';

const Error = () => {
  return (
    <div className={classes.errorParent}>
        <h1>Wikom</h1>
        <div className={classes.errorChild}> 
            <h1><VscError /></h1>
            <h2>Fehler ist aufgetreten</h2>
            <p>Es ist ein Fehler aufgetreten. Bitte keheren Sie zur <Link to={`/`}>zurück</Link>  </p>
        </div>
    </div>
  )
}

export default Error