import React from 'react'
import Modal from '../ui/Modal';
import './ticketModal.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { sanitize } from 'dompurify';


import { useState  , useEffect} from 'react';
import { useSelector , useDispatch } from 'react-redux';
import axios from 'axios';
const TicketModalVersuch = (props) => {
    

    const {hideModalHandler , showModalHandler , isShowModal , updateTicket   , deleteTicketHandler  , projektId , updateTicketHandler , ticketZuweisenHandler , ticketNichtMehrZuweisenHandler } = props;

    const [ticket , setTicket] = useState(props.ticket);
    const [title , setTitle] = useState('');
    const [description, setDescription] = useState('');



    const [user, setUser] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    const [currentId,  setCurrentID] = useState('');
    const [schrittID,  setSchrittID] = useState('');


    const token = useSelector((state) => state.auth.token);

    const userFromLS = JSON.parse(localStorage.getItem('user'));



    useEffect(() => {
      setTicket(props.ticket !== undefined ? props.ticket.title : '')
      setCurrentID(props.ticket !== undefined ? props.ticket._id : '')
      setSchrittID(props.ticket !== undefined ? props.ticket.schrittId._id : '')
      setTitle(props.ticket !== undefined  ? props.ticket.title : '')
      setDescription(props.ticket !== undefined  ? props.ticket.beschreibung : '')
      setUser(props.ticket !== undefined ? props.ticket.user : '')

    } ,[props.ticket])



  const allTicketListFromStore = useSelector((state) => state.ticket.allTicket);
  const dispatch = useDispatch();

  const [allTicket, setAllTicket] = useState(allTicketListFromStore);


   

    const modules = {
        toolbar: [
          [{'header': [1, 2,3, 4 , 5 ,false]}],
          ['bold', 'italic',  'underline' , 'strike', 'blockquote' ],
          [{'list': 'ordered'},  {'list': 'bullet'}, {'indent': '-1'} , {'indent': '+1'} ],
          ['link', 'image' ],
          ['clean'],
    
        ]
      }
    
      const formats = [
        'header',
        'bold', 'italic',  'underline' , 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
      ]


    

   

    const  updateTicketHandlers = async () => {

      if(isShowModal === true && isEdit === true){

        try {

          const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/users/intern/p/schritt/ticket/s/${schrittID}/t/${currentId}`,
           {title: title,  beschreibung: description} ,
          {
              withCredentials: true,
              headers: {Authorization: `Bearer ${token}`}
            }
             
          )
  
         
          setTitle(title);
          setDescription(description);
          updateTicketHandler(props.ticket , title , description);
          setIsEdit(false);
          hideModalHandler();

  
      } catch (error) {
          console.log(error);
      }


      }

    }


    const deleteTicketHandlers = async () => {

    
        
      if(isShowModal === true){

        try {
          const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/users/intern/p/schritt/ticket/s/${schrittID}/t/${currentId}`,
          {
            withCredentials: true,
            headers: {Authorization: `Bearer ${token}`}
          })

          
          deleteTicketHandler(props.ticket);
          hideModalHandler();
          props.onClose();

        } catch (error) {
          console.log(error);
        }
      }
     
    }


    const  ticketZuweisen = async () => {
      setUser(userFromLS.name);
      if(isShowModal === true){
        try {

          const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/users/intern/p/schritt/ticket/s/${schrittID}/t/${currentId}/zuweisen`,
          {
            user: userFromLS.name
          },
          
          {
              withCredentials: true,
              headers: {Authorization: `Bearer ${token}`}
            }
             
          )
  
          
          ticketZuweisenHandler(props.ticket ,userFromLS.name)  

      } catch (error) {
          console.log(error);
      }
      }
    }


    const nichtMehrZuweisen = async () => {
      setUser(null);
      if(isShowModal === true){
        try {

          const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/users/intern/p/schritt/ticket/s/${schrittID}/t/${currentId}/nicht-zuweisen`, 
          {
            user: userFromLS.name
          },
          {
              withCredentials: true,
              headers: {Authorization: `Bearer ${token}`}
            }
             
          )
          ticketNichtMehrZuweisenHandler(props.ticket)
      } catch (error) {
          console.log(error);
      }
      }
     
    }

      const  abbrechenHandler = () => {
        setIsEdit(false);
        hideModalHandler();
        props.onClose();
      }


  return isShowModal &&(
    <Modal>
        <div className="parent">
            <div className="title__action">
              { isEdit &&  <p><input   type='text' name='title' value={title} onChange={(e) => setTitle(e.target.value)}  /></p>}<br/>
              {!isEdit  && <p>{title}</p>}
               { user ? <button>{user.split(' ')[0]}</button> : !isEdit ? <button onClick={ticketZuweisen}>zuweisen</button> : '' }
            </div>
            <div className="ticket_beschreibung">
                <h6>Beschreibung</h6>
               { isEdit && <ReactQuill modules={modules} formats={formats} value={description}  onChange={setDescription}/> }
               { !isEdit  && (<p className="ql-editor" dangerouslySetInnerHTML={{__html: sanitize(description)}}></p>)}
            </div>
            <div className="button__action">
                <button onClick={abbrechenHandler} >Abbrechen</button>
                <button onClick={updateTicketHandlers}>Speichern</button>
                { !isEdit && <button onClick={() =>setIsEdit(true)}>Bearbeiten</button> }
                <button className='danger-button' onClick={deleteTicketHandlers}>Löschen</button>
                {  user ? <p onClick={nichtMehrZuweisen}>Nicht mehr zuweisen</p> : '' } 
            </div>
        </div>
    </Modal>
    
  )
}

export default TicketModalVersuch