import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {  NavLink } from 'react-router-dom';
import classes from './einzelneProjekt.css';


const EinzelneProjekt = (props) => {

  const { id , title , index} = props ; 
  return (
    <>
      <Draggable key={id} draggableId={id} index={index} >
        {
          (provided , snapshot) => (
            <div  ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                <NavLink to={`/projekte/${id}`} 
                style={({isActive}) => 
                isActive ? 
                {color: '#ffbd59' , textDecoration: 'none' , fontWeight: '700'} 
                : {color: 'gray'  , textDecoration: 'none'}} ><p >{title}</p></NavLink>
    
           </div>   
          )
        }
       </Draggable>   
      
    </>
   
  )
}

export default EinzelneProjekt