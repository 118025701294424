import React , {useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './anmelden.css';
import useInputHooks from '../hooks/useInputHooks';


import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useShowPass from '../hooks/useShowPass';

let timeout ;

const Registrieren = () => {


    const [error, setError] = useState('');
    const [sucess, setSucces] = useState('');
    const navigate = useNavigate();
     
     const{
      value : name,
      valueIsValid: nameIsValid,
      hasError: nameHasError,
      getValueChangeHandler: nameChangeHandler,
      getValueOnBlur: nameOnBlurHandler,
      resetValue: resetName,
      errMsg: nameError,
     } = useInputHooks(wert => wert.trim() !== '' && wert.trim().length > 4);
  
     const{
      value : email,
      valueIsValid: emailIsValid,
      hasError: emailHasError,
      getValueChangeHandler: emailChangeHandler,
      getValueOnBlur: emailOnBlur,
      resetValue: resetEmail,
      errMsg: emailError,
     } = useInputHooks(wert => wert.trim() !== '' && wert.includes('@'));
  
  
  
  
  
     const {
      value: password,
      valueIsValid: passwordIsValid,
      hasError: passwordHasError,
      getValueChangeHandler: passwordChangeHandler,
      getValueOnBlur: passwordOnBlur,
      resetValue: resetPassword,
      errMsg: passwordError,
     } = useInputHooks(wert => wert.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{3,}$/) && wert.length > 7 );

     const {
      value: confirmPassword,
      valueIsValid: confirmPasswordIsValid,
      hasError: confirmPasswordHasError,
      getValueChangeHandler: confirmPasswordChangeHandler,
      getValueOnBlur: confirmPasswordOnBlur,
      resetValue: resetConfirmconfirmPassword,
      errMsg: confirmPasswordError,
     } = useInputHooks(wert => wert.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{3,}$/) && wert.length > 7 );

     
     let formIsValid = false;


     if(nameIsValid && emailIsValid && passwordIsValid && confirmPasswordIsValid){
      formIsValid = true;
     }

     const {
      isShowPass : isShowPassword ,
      showPasswordHandler,
     }= useShowPass();

     const {
      isShowPass : isShowConfirmPass ,
      showPasswordHandler:  showConfirmPasswordHandler,
     }= useShowPass();
    

     const registerUser = async (e) => {
      e.preventDefault();
      setError('');
      setSucces('');
  
      try {
          const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/wikomusers/registrieren`, {
            name, email , password , confirmPassword
          })
          const newUser  = await response.data;  
          if(!newUser){
            setError("Couldn't register user . please try again ")
          }else{
            setSucces("KONTO ERFOLGREICH REGISTRIERT");
            setTimeout(() => {
              navigate('/')
            }, 3000)
          }
      } catch (err) {
        setError(err.response.data.message)
      }
    }
  
  return (
    <div className='form__container_parent'>
        <div className="form__container">
        <h1>Wikom</h1>
        {error && <p className='error_message'>{error}</p>}
        {sucess && <p className='success_message'>{sucess}</p>}
        <form action="" onSubmit={registerUser} >

            <div className="name_input">
            { nameHasError && <p className='fe_error' >{nameError("Bitte geben Sie Ihre Name")}</p>}
            <input type="text" name='name'  placeholder='name' value={name} onChange={nameChangeHandler} onBlur={nameOnBlurHandler} />
            </div>
           
              <div className="email_input">
              { emailHasError && <p className='fe_error' >{emailError("Bitte geben Sie ein gültiges Email")}</p>}
              <input type="email"  name='email' placeholder='email' value={email} onChange={emailChangeHandler} onBlur={emailOnBlur}/> 
              </div>

            <div className="passwort_input">
            {passwordHasError && <span className='fe_error' >{passwordError("Das Passwort muss mindestens 8 Zeichen lang sein und eine Kombination aus Klein- und Großbuchstaben, Sonderzeichen sowie Ziffern enthalten.")}</span>}
            <input type={ isShowPassword ? 'text' :  'password'} name='password' placeholder='password' value={password} onChange={passwordChangeHandler} onBlur={passwordOnBlur}/> 
            <h6 onClick={showPasswordHandler}>{ !isShowPassword ?   'EINBLENDEN'  : 'AUSBLENDEN' }</h6> 
            </div>

            <div className="passwort_input">
            {password !== confirmPassword &&  <p  className='fe_error'>{confirmPasswordError("passwörter sind nicht ähnlich")}</p>}
            <input type={ isShowConfirmPass ? 'text' :  'password'}  name='confirmPassword' placeholder='confirm password' value={confirmPassword} onChange={confirmPasswordChangeHandler} onBlur={confirmPasswordOnBlur} onPaste={(e) =>e.preventDefault()}/> 
            <h6 className='showandHideBtn' onClick={showConfirmPasswordHandler}>{ !isShowConfirmPass ?   'EINBLENDEN'  : 'AUSBLENDEN' }</h6>
            </div>
       
            <div className="action_auth">
            <button type='submit'>Registrieren</button>
            <p>Haben Sie schon ein Konto !<Link to={`/`}>Anmelden</Link></p>
             </div>

        </form>
        </div>
    </div>
  )
}

export default Registrieren;