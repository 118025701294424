import { redirect } from "react-router-dom";


export function getSessionZeit(){

    const storedAbgelaufeneSession = localStorage.getItem('sessionZeit');
    const expirateDate = new Date(storedAbgelaufeneSession);
    const now = new Date();
    const duration = expirateDate.getTime() - now.getTime();
    
    return duration;
}

export function getAuthUser(){
    const user = JSON.parse(localStorage.getItem('user')) ;

    const tokenDuration = getSessionZeit();

    if(!user){
        return redirect('/');
    }

    if(tokenDuration < 0){
        return 'EXPIRED';
    }
    return user;
}

export function loaderUser () {
    return getAuthUser();
}


export function checkAuthLoader(){

    const user = getAuthUser();


    if(!user){
       return redirect('/');
    }

    return user;
}

export function abgelaufeneZeitAktualisieren () {

    const  NewAbgelaufeneZeit =  Date.now()  +  (1000 * 60 * 20 );
    
    localStorage.setItem('sessionZeit' , NewAbgelaufeneZeit)

}
