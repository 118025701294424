import React from 'react';
import classes from './wilkommen.module.css';

const Wilkommen = (props) => {

  const {addProjektHandler , projektTitle , setProjektTitle} = props

  return (
    <div className={classes.wilkommenparent}>
        
           <h1>Wikom</h1>
            <h6 onClick={props.abmelden}>ausloggen</h6>
       
        <div className={classes.wilkommenBox}>
            <div className={classes.child}>
                <h1>Wilkommen bei <span>W</span>ikom-Tools</h1>
                <input type='text' name='neuProjekt'  value={projektTitle} placeholder='Bitte geben Sie die Name Ihres Projekts' onChange={(e) => setProjektTitle(e.target.value)} />
                <button onClick={addProjektHandler} type='submit' >Projekt einfügen</button>
            </div>
        </div>
    </div>
  )
}

export default Wilkommen